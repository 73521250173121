import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="Footer pt-5">
      <div className="overlay"></div>

      <div className="container py-5  position-relative">
        <div className="row">
          <div className="col-md-3 my-4">
            <Link to="/">
              <img src={logo} className="w-50" />
            </Link>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3 col-6">
                <h6>{t("Syndicate")}</h6>
                <ul className="list-inline ">
                  <div>
                    <HashLink to="/BannerAbout#section2">
                      {t("About-Syndicate")}
                    </HashLink>
                  </div>
                  <div>
                    <HashLink to="/BannerAbout#section3">
                      {t("Vision")}{" "}
                    </HashLink>
                  </div>
                  <div>
                    <HashLink to="/BannerAbout#section5">
                      {t("Captains")}{" "}
                    </HashLink>
                  </div>
                </ul>
              </div>
              <div className="col-md-3 col-6">
                <h6>{t("Goal")}</h6>
                <ul className="list-inline">
                  <a href="/BannerGoals">
                    {" "}
                    <li>{t("Goals")}</li>
                  </a>
                  <a href="/BannerBenefits">
                    <li>{t("Discover-Affiliate")}</li>
                  </a>
                </ul>
              </div>
              <div className="col-md-3 col-6">
                <h6>{t("Social-Media")}</h6>
                <div className="Socila">
                  <a
                    href="https://www.facebook.com/p/Jordanian-Car-Rental-Association-100064844643893/?locale=ar_AR"
                    traget="_blank"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container position-relative">
        <div className="row">
          <p className="text-center text-white fw-bold">{t("copy-right")}</p>
        </div>
      </div>
    </section>
  );
};
